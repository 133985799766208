function detectTouchDevice() {
    var isTouch = ('ontouchstart' in document.documentElement);
    if (isTouch) {
        $('html').addClass('touch');
    } else {
        $('html').addClass('no-touch');
    }
}

function bags() {
    $.getScript("/typo3conf/ext/zgnaturel/Resources/Public/JavaScripts/jquery.nicescroll.min.js", function () {
        $('section:not(.products), header').on('click mouseenter', function () {
            $('.bag').removeClass('active').children('.bagPaper').children('.scroller').getNiceScroll().remove();
        });
    });

    var $slideItem = $('.products .uk-slider-items > li');
    $slideItem.on('beforeitemhide', function () {
        $('.bag').removeClass('active');
    });
}

function layernav() {
    var $item = $('.nav-item.products');
    $item.on('click', function () {
        $(this).toggleClass('open');
    });
    $('.nav-item:not(.products)').on('click', function () {
        $item.removeClass('open');
    });
    $(window).scroll(function () {
        $item.removeClass('open');
    });
}

function matchMedia() {
    var media1023px = window.matchMedia("(max-width: 1023px)");
    if (media1023px.matches) {
        UIkit.slider('.bags', {
            center: true,
            index: 1
        });
        // UIkit.slideshow('#heroslideshow', {
            // animation: "fade",
            // autoplay: true,
            // ratio: "1:2"
        // });
    }
}

function resizeWindow() {
    var $window = $(window);
    var windowWidth = $window.width();
    var timeout = 1000;
    $window.resize(function () {
        if ($window.width() != windowWidth) {
            windowWidth = $window.width();
            $("html").addClass("resizing");
            setTimeout(function () {
                $("html").removeClass("resizing");
            }, timeout);
        }
        if ($window.width() >= 1024 && $('.bags.uk-slider').length > 0) {
            setTimeout(function () {
                UIkit.slider('.bags').$destroy();
            }, timeout);
        }
        if ($window.width() <= 1023) {
            setTimeout(function () {
                UIkit.slider('.bags', {
                    center: true,
                    index: 1
                });
            }, timeout);
        }
    });
}

function modal() {
    UIkit.toggle('.nav-main .nav-item.order a, .btn-order', {
        target: "#order"
    });
}

import {modalPageLoader} from './modules/modal'

function init() {
    if($('.bags').length > 0){
        $('body').addClass('home');
    }
    modalPageLoader();
    matchMedia();
    resizeWindow();
    bags();
    detectTouchDevice();
    layernav();
    modal();
}


// Init jQuery thinks here //

var jqueryReadyFunction = function () {
    init();
};

// This must be an the END of your JS File //

if (typeof jQuery == 'undefined') {
    window.onload = function () {
        jqueryReadyFunction();
    }
} else {
    jqueryReadyFunction();
}

// This must be an the END of your JS File //
