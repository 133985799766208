const modalPageLoader = function () {
    document.addEventListener('click', function (event) {
        if (event.target.classList[1] !== 'openmodal') return;
        event.preventDefault();
        $.get(modalUrl, function(data) {
            UIkit.modal.dialog(data + '<button class="uk-modal-close-default" type="button" uk-close></button>');
        });
    }, false);
};

export {modalPageLoader};
